var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isDataLoaded)?_c('b-card',[_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]):_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"name"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab !== idx ? 'hidden' : '',attrs:{"vid":("name." + k),"name":_vm.$t('general.field_lang', {
                      field: _vm.$t('labels.title'),
                      lang: _vm.$t(("general." + (i.toLocaleLowerCase()))),
                    }),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', {
                        field: _vm.$t('labels.title'),
                        lang: _vm.$t(("general." + (i.toLocaleLowerCase()))),
                      })}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('general.field_lang', {
                          field: _vm.$t('labels.title'),
                          lang: _vm.$t(("general." + (i.toLocaleLowerCase()))),
                        })},model:{value:(_vm.form.name[k]),callback:function ($$v) {_vm.$set(_vm.form.name, k, $$v)},expression:"form.name[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"country_id","name":_vm.$t('country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('country')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name_label","options":_vm.countries,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('country')},model:{value:(_vm.form.country_id),callback:function ($$v) {_vm.$set(_vm.form, "country_id", $$v)},expression:"form.country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('labels.channel_id'),"vid":"channel_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.channel_id')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('labels.channel_id'),"autocomplete":"off"},model:{value:(_vm.form.channel_id),callback:function ($$v) {_vm.$set(_vm.form, "channel_id", $$v)},expression:"form.channel_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('labels.status'))+" ")]),_c('b-form-checkbox',{attrs:{"checked":"true","value":"enabled","unchecked-value":"disabled","name":"check-button","switch":""},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('labels.send_push_notification_for_new_video'))+" ")]),_c('b-form-checkbox',{attrs:{"value":"1","unchecked-value":"0","name":"check-button","switch":""},model:{value:(_vm.form.push_notification_status),callback:function ($$v) {_vm.$set(_vm.form, "push_notification_status", $$v)},expression:"form.push_notification_status"}})],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('LoadingButton'),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('labels.reset'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }